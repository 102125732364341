import React from "react"
import { graphql } from "gatsby"
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap"
import Layout from "../components/layout.de"
import SEO from "../components/seo"
import TitleBlock from "../components/blocks/TitleBlock"
import Img from "gatsby-image"
import HorizontalCardBlock from "../components/blocks/HorizontalCardBlog"
import Photos from "../components/general/Photos"
import ContactInfo from "../components/general/ContactInfo"
import Map from "../components/general/Map"
import BigHeader from "../components/header"

const IndexPage = ({ data }) => {
  React.useEffect(() => {
    document.body.classList.add("sections-page")
    document.body.classList.add("sidebar-collapse")
    document.documentElement.classList.remove("nav-open")
    var href = window.location.href.substring(
      window.location.href.lastIndexOf("#/") + 2
    )
    var hrefId = href.substring(href.lastIndexOf("#") + 1)

    if (href.lastIndexOf("#") > 0) {
      document.getElementById(hrefId).scrollIntoView({
        behavior: "smooth",
        inline: "nearest",
      })
    }

    return function cleanup() {
      document.body.classList.remove("sections-page")
      document.body.classList.remove("sidebar-collapse")
    }
  })

  return (
    <Layout>
      <SEO title="Homepage" lang="en" />
      <BigHeader
        title={`Komfortable Appartements in Strandnähe`}
        description={`Morska 5 ist eine neue Freizeiteinrichtung in einer wunderschönen Umgebung, in der ersten Linie der Küstengebäude , knapp 50 m vom Strand entfernt. Bequeme und gut ausgerüstete Zimmer sorgen für komfortable Freizeitverbringung.`}
        ctaText={`Aufenthalt buchen`}
        ctaTo={`/de/#contact`}
      />

      <Container>
        <div className="section pb-0">
          <Row>
            <Col className="ml-auto mr-auto text-center title" md="6">
              <TitleBlock
                title={`Ein neuer Ort auf der Karte von Horst/Niechorze`}
                description={`Komfortable Innenräume, modernes Gebäude, attraktive Lage. Wir verfügen über alles Erforderliche, um die Attraktivität des Ostsee-Kurorts in vollem Umfang zu genießen.`}
                headingLevel="h2"
                extendedClasses="mb-0 color-sea font-weight-bold"
              />
            </Col>
          </Row>
          <div className="section-story-overview pb-0">
            <Row>
              <Col className="ml-auto mr-auto" md="4">
                <div className="image-container image-left">
                  <Img
                    fluid={data.story1.childImageSharp.fluid}
                    style={{ position: "asbolute" }}
                  />
                </div>
                <div className="text-under-image">
                  <h3
                    style={{
                      color: "#3D3D3F",
                      paddingTop: "30px",
                    }}
                    className="text-center text-md-left"
                  >
                    Vermietung von Zimmern und Appartements - Morska 5
                  </h3>
                </div>
              </Col>
              <Col className="ml-auto mr-auto" md="4">
                <div className="image-container image-right">
                  <Img
                    fluid={data.story2.childImageSharp.fluid}
                    style={{ position: "asbolute" }}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto" md="10">
                <p
                  style={{
                    textAlign: "justify",
                  }}
                >
                  Morska 5 ist eine moderne Freizeiteinrichtung in der
                  unmittelbaren Nähe des sauberen, breiten und sandigen
                  Strandes, sowie neben Geschäften, Restaurants und Bars.
                </p>
                <p
                  style={{
                    textAlign: "justify",
                  }}
                >
                  Die umliegenden Gebäude und Grünflächen haben einen
                  traditionellen Küstencharakter und sind von den von Natur aus
                  wunderschönen Bäumen und Dünen umgeben. Das Gebäude ist mit
                  einem Aufzug und Parkmöglichkeiten ausgestattet und jedes
                  Appartement hat eigenen Balkon.
                </p>
                <p
                  style={{
                    textAlign: "justify",
                  }}
                >
                  In den oberen Etagen gibt es von den Balkons aus eine
                  herrliche Aussicht auf die Ostsee und den Leuchtturm. Das
                  gepflegte Gelände um das Gebäude herum bietet für einen Ort
                  zum Entspannen oder zum Grillen mit Bänken und bequemen
                  Sitzen.
                </p>
                <p
                  style={{
                    textAlign: "justify",
                  }}
                >
                  Wir haben auch an die jüngsten Gäste gedacht, die ihre Zeit
                  auf einem kinderfreundlichen Spielplatz oder in einem
                  Spielraum verbringen können, wo es Bausteine, Spielzeuge sowie
                  eine Spielkonsole gibt.
                </p>
              </Col>
            </Row>
          </div>
        </div>
      </Container>

      <Container>
        <div className="section pt-0 pb-0">
          <div className="section-story-overview pb-0">
            <Row>
              <Col className="ml-auto" md="6">
                <div className="image-container image-left">
                  <Img
                    fluid={data.story4.childImageSharp.fluid}
                    style={{ position: "asbolute" }}
                  />
                  <p
                    className="blockquote border-sea color-sea"
                    style={{ fontWeight: 500 }}
                  >
                    "Horst/Niechorze zieht jedes Jahr die Anhänger des süßen
                    Nichttuns und des Sonnenbadens, sowie die Fans von
                    Wassersport, Wanderungen und Radtouren an“. <br></br>
                    <br />
                  </p>
                </div>
                <div className="image-container">
                  <Img
                    fluid={data.story3.childImageSharp.fluid}
                    style={{ position: "asbolute" }}
                  />
                </div>
              </Col>
              <Col className="mr-auto" md="4">
                <div className="image-container image-right">
                  <Img
                    fluid={data.story5.childImageSharp.fluid}
                    style={{ position: "asbolute" }}
                  />
                </div>
                <h3 className="text-center text-md-left">
                  Horst/Niechorze – überrascht mit Vielfalt
                </h3>
                <p className="text-justify text-md-left">
                  Horst/Niechorze ist ein ehemaliges Fischerdorf, das jetzt zu
                  einem sich am intensivsten entwickelnden touristischen Ort an
                  der Ostsee wurde. Dieses Dorf in der Gemeinde Rewal/Rewahl mit
                  ca. 1000 Einwohnern ist wegen seiner herrlichen Lage und des
                  Leuchtturms berühmt. Der westliche Teil von Niechorze/Horst
                  liegt auf einer 22 Meter hohen Klippe und bietet einen
                  atemberaubenden Ausblick. Der östliche Teil des Dorfes liegt
                  auf einer Sandnehrung zwischen der Ostsee und einem Strandsee
                  Eiersberger See.
                </p>
                <p className="text-justify text-md-left">
                  Morska 5 liegt nur 50 m entfernt vom Eingang zum breiten,
                  sauberen Strand. In direkter Nähe gibt‘s auch die Hauptstraße,
                  wo man zahlreiche Restaurants, Cafés und Geschäfte finden
                  kann. Nicht ganz ein Kilometer von unserem Objekt steht das
                  Wahrzeichen von Niechorze/Horst - ein Leuchtturm. Sehenswert
                  ist auch ein historischer Bahnhof der Schmalspurbahn (250 m)
                  oder Sie können auf herrlichen Routen gemütlich spazieren.
                </p>
                <p className="text-justify text-md-left">
                  Die Anhänger von Radtouren werden bestimmt mit einer Radroute
                  durch den Wald, der Künste entlang von Niechorze/Horst bis zu
                  Treptower Deep oder einer malerischen Radroute rund um den
                  Eiersberger See und das ornithologische Reservat begeistert
                  sein.
                </p>
              </Col>
            </Row>
          </div>
        </div>
      </Container>

      <div className="section front__blog pt-0 pb-0" id="apartments">
        <div className="section-story-overview cd-section pt-0 pb-0">
          <div className="blogs-3">
            <Container className="mb-4">
              <Row>
                <Col className="ml-auto mr-auto text-center title mb-4" md="6">
                  <TitleBlock
                    title={`Hervorragend ausgestattete Zimmer und Appartements warten auf Sie`}
                    description={`Alle unsere Appartements setzen sich aus einer Kochnische, einem Badezimmer, einem Balkon zusammen und haben volle Ausstattung.`}
                    headingLevel="h2"
                    extendedClasses="mb-0 color-gold font-weight-bold"
                  />
                </Col>
              </Row>
            </Container>

            <Container className="apartments">
              <Row>
                <Col className="ml-auto mr-auto" md="10">
                  <HorizontalCardBlock
                    title={`Appartement für 2 Personen`}
                    description={`Ein gemütliches, voll ausgestattetes Appartement für 2 Personen, mit Kochnische, Badezimmer und Balkon. Bilder und Details zur Ausstattung ansehen.`}
                    link="/de/apartament-2-osobowy"
                    image={data.roomCovers.edges[0].node.childImageSharp.fluid}
                    ctaText={`Appartement ansehen`}
                  />

                  <HorizontalCardBlock
                    title={`Appartement für 3 Personen`}
                    description={`Ein vollständig ausgestattetes Appartement für 3 Personen, das sich aus einem Wohnzimmer mit Schlafsofa, einer Kochnische, einem separaten Schlafzimmer mit einem breiten Bett sowie einem Badezimmer und einem Balkon zusammensetzt. Bilder und Details zur Ausstattung ansehen.`}
                    link="/de/apartament-3-osobowy"
                    image={data.roomCovers.edges[1].node.childImageSharp.fluid}
                    ctaText={`Appartement ansehen`}
                  />

                  <HorizontalCardBlock
                    title={`Appartement für 3 Personen (2 Zimmer)`}
                    description={`Ein vollständig ausgestattetes Appartement für 3 Personen, das sich aus einem Wohnzimmer mit Schlafsofa, einer Kochnische, einem separaten Schlafzimmer mit einem breiten Bett sowie einem Badezimmer und einem Balkon zusammensetzt. Bilder und Details zur Ausstattung ansehen.`}
                    link="/de/apartament-3-osobowy-2-pokojowy"
                    image={data.roomCovers.edges[2].node.childImageSharp.fluid}
                    ctaText={`Appartement ansehen`}
                  />

                  <HorizontalCardBlock
                    title={`Appartement für 4 Personen (2 Zimmer)`}
                    description={`Ein komfortables Appartement mit 2 Zimmer für 4 Personen, das sich aus einem Wohnzimmer mit Schlafsofa, einer Kochnische, einem Schlafzimmer mit 2 Betten, einem Badezimmer und einem bequemen Balkon zusammensetzt. Bilder und Details zur Ausstattung ansehen.`}
                    link="/de/apartament-4-osobowy"
                    image={data.roomCovers.edges[3].node.childImageSharp.fluid}
                    ctaText={`Appartement ansehen`}
                  />

                  <HorizontalCardBlock
                    title={`Appartement für 5 Personen (2 Zimmer)`}
                    description={`Ein komfortables Appartement mit 2 Zimmer für 5 Personen, das sich aus einem Wohnzimmer mit Schlafsofa für 2 Personen, einer Kochnische, einem Schlafzimmer mit 2 Betten (Doppelbett Double und Einzelbett Twin), einem Badezimmer und einem Balkon zusammensetzt. Bilder und Details zur Ausstattung ansehen.`}
                    link="/de/apartament-5-osobowy"
                    image={data.roomCovers.edges[4].node.childImageSharp.fluid}
                    ctaText={`Appartement ansehen`}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>

      <div className="section front__info pb-0">
        <div className="projects-4 pt-4 pb-4" data-background-color="gray">
          <Container fluid>
            <Row>
              <Col className="px-0 order-md-1" md="6">
                <Card className="card-fashion card-background">
                  <Img
                    fluid={data.bg3Front.childImageSharp.fluid}
                    style={{
                      position: "absolute",
                      left: 0,
                      top: 0,
                      width: "100%",
                      height: "100%",
                    }}
                  />
                  <CardBody>
                    <CardFooter className="text-left">
                      <div className="stats">
                        <span>
                          <i className="now-ui-icons objects_globe" />
                          Großes gastronomisches Angebot
                        </span>
                      </div>
                    </CardFooter>
                    <CardTitle className="text-left" tag="div">
                      <h2>
                        Nur wenige Fußminuten zu Restaurants und Geschäften
                      </h2>
                    </CardTitle>
                  </CardBody>
                </Card>
              </Col>
              <Col className="px-0 order-md-2" md="6">
                <div className="card-container">
                  <Card className="card-fashion">
                    <CardTitle tag="div">
                      <h4>
                        Ein sicheres und attraktives Spielplatz sowie ein
                        Spielraum mit Spielkonsole und kreativen Spielzeugen für
                        die Kinder.
                      </h4>
                    </CardTitle>
                    <CardBody>
                      <CardFooter>
                        <div className="stats">
                          <span>
                            <i className="now-ui-icons education_paper" />
                            Zahlreiche Attraktionen für unsere jüngsten Gäste
                          </span>
                        </div>
                      </CardFooter>
                    </CardBody>
                  </Card>
                  <Card className="card-fashion card-background d-none d-md-block">
                    <Img
                      fluid={data.bg1Front.childImageSharp.fluid}
                      style={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </Card>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="px-0 order-md-3 order-4" md="6">
                <div className="card-container">
                  <Card className="card-fashion card-background d-none d-md-block">
                    <Img
                      fluid={data.bg4Front.childImageSharp.fluid}
                      style={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </Card>
                  <Card className="card-fashion arrow-left">
                    <CardTitle tag="div">
                      <h4>
                        Auf dem Objektgelände gibt es einen Grillplatz, einen
                        Ort zum Entspannen und für Aktivitäten im Freundeskreis.
                      </h4>
                    </CardTitle>
                    <CardBody>
                      <CardFooter>
                        <div className="stats">
                          <span>
                            <i className="now-ui-icons design_app" />
                            Ausschließlich für unsere Gäste
                          </span>
                        </div>
                      </CardFooter>
                    </CardBody>
                  </Card>
                </div>
              </Col>
              <Col className="px-0 order-md-4 order-3" md="6">
                <Card className="card-fashion card-background">
                  <Img
                    fluid={data.bg2Front.childImageSharp.fluid}
                    style={{
                      position: "absolute",
                      left: 0,
                      top: 0,
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      <Col md="10" className="mr-auto ml-auto">
        <div className="section" id="photos">
          <Photos
            title="Bilder von Morska 5 ansehen"
            description="Wir haben nicht nur für die Zimmerausstattung gesorgt, sondern auch für freundliche und praktische Umgebung. Prüfen Sie, was Morska 5 den Gästen anzubieten hat."
            photos={data.gallery}
          />
        </div>
      </Col>

      <Col md="7" className="ml-auto mr-auto">
        <div className="section" id="contact">
          <ContactInfo
            title={`Aufenthalt buchen`}
            description={`Falls Sie an der Buchung interessiert sind, rufen Sie uns an der senden Sie uns eine E-Mail. Eine Voraussetzung für die Bestätigung der Buchung ist eine Anzahlung in Höhe von 30% des gesamten Aufenthaltswertes.`}
            telTitle={`Klicken Sie auf und rufen Sie uns an`}
            telDescription={`Auf Ihren Anruf warten wir 7 Tage der Woche, 8:00 bis 22:00 Uhr`}
            mailDescription={`In Ihrer E-Mail-Nachricht geben Sie bitte den Aufenthaltstermin, die Anzahl und Art der Zimmer und Ihre Telefonnummer an. `}
            mailTitle={`Klicken Sie und schreiben Sie mit uns`}
          />
        </div>
      </Col>

      <div className="section contactWithMap pb-0">
        <Map
          description={`Sie finden uns ohne weiteres in Google Maps. Falls Sie Probleme mit der Zufahrt haben, rufen Sie uns an.`}
          ctaText={`Zufahrt anzeigen`}
          lang="de"
        />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query imagesDe {
    story1: file(relativePath: { eq: "story-1.jpg" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    story2: file(relativePath: { eq: "story-2.jpg" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    story3: file(relativePath: { eq: "story-3.jpg" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    story4: file(relativePath: { eq: "story-4.jpg" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    story5: file(relativePath: { eq: "story-5.jpg" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    temporaryRoom: file(relativePath: { eq: "temporary-room.jpg" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bg3Front: file(relativePath: { eq: "bg-3-front.jpg" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bg1Front: file(relativePath: { eq: "bg-1-front.jpg" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bg4Front: file(relativePath: { eq: "bg-4-front.jpg" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bg2Front: file(relativePath: { eq: "bg-2-front.jpg" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    gallery: allFile(
      filter: {
        relativeDirectory: { in: ["gallery/1", "gallery/2", "gallery/3"] }
      }
      sort: { fields: relativeDirectory }
    ) {
      edges {
        node {
          id
          childImageSharp {
            sizes(maxWidth: 1800) {
              ...GatsbyImageSharpSizes_withWebp
            }
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    roomCovers: allFile(
      sort: { fields: name }
      filter: { relativeDirectory: { eq: "rooms/covers" } }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

export default IndexPage
